import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { withTheme } from 'styled-components';
import { compose } from 'lodash/fp';
import Link from 'next/link';

import { dictionaryItem } from '../../hocs/withDictionary';
import formatPrice from '../../lib/formatPrice';
import formatAddress from '../../lib/formatAddress';
import { stringifyPartyMembers } from '../../lib/helpers/party';

import { Row, Col } from '../ui/Grid';
import Title from '../ui/Title';

import {
  RemoveButton,
  WhiteButton,
  Image,
  Actions,
  MiniBasketItem,
  Price,
  PriceCrossings,
  TotalPrice,
  Header,
  MessageWrapper,
  Address,
  Campsite,
  BookingLink,
} from './BasketItem.style';

import ErrorTypeMessage from '../ErrorTypeMessage';
import QuoteRemoveComponent from '../Quote/QuoteRemoveComponent';
import QuoteAcceptComponent from '../Quote/QuoteAcceptComponent';
import * as QuoteChangeTypes from '../../constants/QuoteChangeTypes';
import ConditionalWrapper from '../ConditionalWrapper';
import BasketScopedOverlappingSitesType from '../../config/basketScopedOverlappingSitesType';
import IbePropTypes from '../../IbePropTypes';
import { Text } from '../ui';
import routes from '../../constants/routes';
import BasketItemImportantInfo from '../ManageMyBookings/BasketItemImportantInfo';

class BasketItem extends Component {
  static propTypes = {
    acceptButton: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    amendLoading: PropTypes.bool,
    changeMessage: PropTypes.string,
    changePrice: PropTypes.number,
    hasUserAttemptedCheckout: PropTypes.bool,
    isPreviousBookingBasket: PropTypes.bool,
    isBookingBasket: PropTypes.bool,
    isOverseas: PropTypes.bool,
    isItx: PropTypes.bool.isRequired,
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
      COLOR_DANGER: PropTypes.string,
      COLOR_SUCCESS: PropTypes.string,
      COLOR_WARNING: PropTypes.string,
    }),
    campsite: PropTypes.shape(IbePropTypes.campsite),
    isAmend: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    bigBasket: PropTypes.bool,
    onEditClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    onRemoveResetSession: PropTypes.func,
    onViewClick: PropTypes.func,
    onCancelAmending: PropTypes.func,
    basketScopedOverlappingSitesType: PropTypes.string,
    componentId: PropTypes.string,
    logo: PropTypes.string,
    defaultCampsiteLogoImage: PropTypes.string,
    totalPrice: PropTypes.number.isRequired,
    router: PropTypes.shape({
      query: PropTypes.shape({
        componentId: PropTypes.string,
      }),
    }),
    changeType: PropTypes.number,
    quoteChangeTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    openRemovePackagePopUp: PropTypes.func,
    openRemoveVouchersPopUp: PropTypes.func,
    openCancelSiteBookingPopUp: PropTypes.func,
    wouldInvalidateItx: PropTypes.bool,
    wouldInvalidateVouchers: PropTypes.bool,
    wouldBasketScopedOverlap: PropTypes.bool,
    advancedPitch: PropTypes.bool,
    partyMemberTypes: PropTypes.arrayOf(PropTypes.shape({})),
    partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
    parentPitchId: PropTypes.number,
    penaltyDate: PropTypes.string,
    isPastPenaltyDate: PropTypes.bool,
    preBookingCheckIssueTypes: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    })),
    preventBookingComponents: PropTypes.arrayOf(PropTypes.shape(
      IbePropTypes.bookingValidationComponent,
    )),
    index: PropTypes.number,
    partyMemberReference: PropTypes.arrayOf(PropTypes.number),
    warnBookingComponents: PropTypes.arrayOf(PropTypes.shape(
      IbePropTypes.bookingValidationComponent,
    )),
  };

  static defaultProps = {
    acceptButton: null,
    amendLoading: false,
    theme: {
      COLOR_WHITE: '',
      COLOR_DANGER: '',
      COLOR_SUCCESS: '',
      COLOR_WARNING: '',
    },
    changeMessage: '',
    changePrice: null,
    hasUserAttemptedCheckout: false,
    isAmend: false,
    isOverseas: false,
    campsite: {},
    bigBasket: false,
    children: null,
    onEditClick: null,
    onCancelClick: null,
    onRemoveResetSession: null,
    onViewClick: null,
    onCancelAmending: null,
    basketScopedOverlappingSitesType: BasketScopedOverlappingSitesType.OFF,
    componentId: '',
    logo: '',
    defaultCampsiteLogoImage: '',
    router: {
      query: {
        componentId: '',
      },
    },
    changeType: 0,
    openRemovePackagePopUp() { },
    openRemoveVouchersPopUp() { },
    openCancelSiteBookingPopUp() { },
    wouldInvalidateItx: false,
    wouldInvalidateVouchers: false,
    wouldBasketScopedOverlap: false,
    isPreviousBookingBasket: false,
    isBookingBasket: false,
    advancedPitch: false,
    partyMemberTypes: [],
    partyMembers: [],
    partyMemberReference: [],
    parentPitchId: 0,
    penaltyDate: '',
    isPastPenaltyDate: false,
    index: 1,
    preBookingCheckIssueTypes: [],
    preventBookingComponents: [],
    warnBookingComponents: [],
  };

  state = {
    error: null,
    isLoading: false,
  };

  handleError = (error) => {
    this.setState({ error });
  };

  getPreventBookingComponentMessage = (issue) => {
    const foundType = this.props.preBookingCheckIssueTypes.find(
      type => type.key === issue.issueType,
    );

    const bookingBaseUrl = `${routes.myBookingsUpcoming}/`;

    if (foundType) {
      return (
        <>
          <Text span dictionary={dictionaryItem('PreBookingCheckIssue', foundType.value || 'NotSet')} />
          <Text span>{issue.relatedBookingIds?.map((id, index, array) => (
            <Link href={`${bookingBaseUrl}${id}`} as={`${bookingBaseUrl}${id}`} key={`related-bookings-link-${id}`}>
              <>
                <BookingLink href={`${bookingBaseUrl}${id}`}>
                  {id}
                </BookingLink>
                {index + 1 !== array.length ? ((', ')) : (('.'))}
              </>
            </Link>
          ))}
          </Text>
        </>
      );
    }

    return (
      <Text dictionary={dictionaryItem('PreBookingCheckIssue', 'NotSet')} />
    );
  }

  render() {
    const changeTypeData = this.props.quoteChangeTypes.find(
      o => o.key === this.props.changeType,
    );

    const changeTypeName = changeTypeData ? changeTypeData.value : QuoteChangeTypes.NONE;

    let borderColor;

    const availabilityChange =
      changeTypeName === QuoteChangeTypes.PRICE_INCREASE
      || changeTypeName === QuoteChangeTypes.PRICE_DECREASE
      || changeTypeName === QuoteChangeTypes.NOT_AVAILABLE;

    if (availabilityChange) {
      borderColor = QuoteChangeTypes.PRICE_DECREASE
        ? this.props.theme.COLOR_SUCCESS
        : this.props.theme.COLOR_DANGER;
    } else if (
      (
        this.props.wouldBasketScopedOverlap
        && this.props.hasUserAttemptedCheckout
        && this.props.basketScopedOverlappingSitesType === BasketScopedOverlappingSitesType.PREVENT
      ) || (
        this.props.preventBookingComponents.length
      )
    ) {
      borderColor = this.props.theme.COLOR_DANGER;
    } else if (this.props.warnBookingComponents.length) {
      borderColor = this.props.theme.COLOR_WARNING;
    }

    let removeButtonFunc;
    if (this.props.wouldInvalidateVouchers) {
      removeButtonFunc = () => this.props.openRemoveVouchersPopUp();
    }
    if (this.props.isItx && this.props.wouldInvalidateItx) {
      removeButtonFunc = () => this.props.openRemovePackagePopUp();
    }
    if (!this.props.isPreviousBookingBasket && this.props.isBookingBasket) {
      removeButtonFunc = () => this.props.openCancelSiteBookingPopUp(this.props.totalPrice);
    }
    if (this.props.onRemoveResetSession) {
      removeButtonFunc = () => (
        this.setState({ isLoading: true }, this.props.onRemoveResetSession)
      );
    }
    if (this.props.isBookingBasket && this.props.onCancelClick) {
      removeButtonFunc = this.props.onCancelClick;
    }
    if (!this.props.isBookingBasket && this.props.isAmend && this.props.onCancelAmending) {
      removeButtonFunc = this.props.onCancelAmending;
    }

    const party = this.props.partyMemberReference?.map((referenceId) => (
      this.props.partyMembers?.find(partyMember => partyMember.personId === referenceId)
    )).filter(partyMember => !!partyMember);
    const partyString = stringifyPartyMembers(
      party,
      this.props.partyMemberTypes,
    );

    const title = this.props.parentPitchId
      ? `Guest Party ${this.props.index}`
      : this.props.campsite.name;

    const description = this.props.parentPitchId
      ? partyString
      : formatAddress(this.props.campsite.address);
    return (
      <MiniBasketItem
        borderColor={borderColor}
        subItem={this.props.advancedPitch}
      >
        <Header>
          {this.props.advancedPitch ? (
            <Image svg />
          ) : (
            <Image src={this.props.logo || this.props.defaultCampsiteLogoImage} />
          )}
          {this.props.bigBasket && (
            <Campsite full={this.props.bigBasket}>
              <Title tag={3} size={4}>{title}</Title>
              <Address>{description}</Address>
            </Campsite>
          )}
          {/* render price when its not ITX and its not Unavailable */}
          {!this.props.isItx && changeTypeName !== QuoteChangeTypes.NOT_AVAILABLE &&
            <div>
              {availabilityChange
                ? (
                  <PriceCrossings>
                    <TotalPrice strikeThrought>{`${formatPrice(this.props.totalPrice)}`}</TotalPrice>
                    <TotalPrice borderColor={borderColor}>&nbsp;{`${formatPrice(this.props.changePrice)}`}</TotalPrice>
                  </PriceCrossings>
                )
                : (
                  <Price>
                    <TotalPrice>{`${formatPrice(this.props.totalPrice)}`}</TotalPrice>
                  </Price>
                )
              }
            </div>
          }
        </Header>

        {/*
          This BasketItemImportantInfo is used for UK bookings which have an
          important info section for every site on the booking. For OS bookings
          we show a single item at the top of the basket. This is dealt with in
          BasketContent.jsx.
        */}
        {
          this.props.isBookingBasket &&
          !this.props.isPreviousBookingBasket &&
          !this.props.isOverseas &&
            <BasketItemImportantInfo
              isPastPenaltyDate={this.props.isPastPenaltyDate}
              penaltyDate={this.props.penaltyDate}
              isOverseas={false}
            />
        }

        {this.props.children}

        <Actions bigBasket={this.props.bigBasket}>
          <Row size="small">
            {!!this.props.onViewClick && !this.props.advancedPitch && (
              <Col>
                <WhiteButton
                  dictionary={dictionaryItem('BasketItem', 'ViewSite')}
                  onClick={this.props.onViewClick}
                  size="small"
                />
              </Col>
            )}
            {!this.props.isPreviousBookingBasket &&
              <>
                {!!this.props.onEditClick && (
                  <Col>
                    <WhiteButton
                      dictionary={dictionaryItem('BasketItem', this.props.isBookingBasket ? 'AmendStay' : 'EditSite')}
                      disabled={!!this.props.router.query.componentId}
                      onClick={this.props.onEditClick}
                      size="small"
                      isLoading={this.props.amendLoading}
                    />
                  </Col>
                )}
                {!!this.props.acceptButton &&
                  <Col flex="none">
                    <QuoteAcceptComponent
                      componentIds={this.props.componentId}
                      onError={this.handleError}
                    >
                      {this.props.acceptButton}
                    </QuoteAcceptComponent>
                  </Col>
                }
                <Col>
                  <ConditionalWrapper
                    condition={!removeButtonFunc}
                    wrapper={children => (
                      <QuoteRemoveComponent
                        componentIds={this.props.componentId}
                        onError={this.handleError}
                      >
                        {children}
                      </QuoteRemoveComponent>
                    )}
                  >
                    <RemoveButton isLoading={this.state.isLoading} onClick={removeButtonFunc} />
                  </ConditionalWrapper>
                </Col>
              </>
            }
          </Row>
        </Actions>
        <Row
          size="small"
        >
          <MessageWrapper>
            {(
              changeTypeName === QuoteChangeTypes.PRICE_INCREASE
              || changeTypeName === QuoteChangeTypes.NOT_AVAILABLE
            ) &&
              <ErrorTypeMessage
                bgColor={borderColor}
                marginTop
                loading={false}
              >
                {this.props.changeMessage}
              </ErrorTypeMessage>
            }

            {!!this.state.error &&
              <ErrorTypeMessage
                marginTop
                error={this.state.error}
                loading={false}
              />
            }

            {this.props.preventBookingComponents.map(component => (
              <>
                {component.issues?.map(issue => (
                  <ErrorTypeMessage
                    marginTop
                    loading={false}
                  >
                    {this.getPreventBookingComponentMessage(issue)}
                  </ErrorTypeMessage>
                ))}
              </>
            ))}

            {this.props.warnBookingComponents.map(component => (
              <>
                {component.issues?.map(issue => (
                  <ErrorTypeMessage
                    marginTop
                    loading={false}
                    bgColor={this.props.theme.COLOR_WARNING}
                  >
                    {this.getPreventBookingComponentMessage(issue)}
                  </ErrorTypeMessage>
                ))}
              </>
            ))}
          </MessageWrapper>
        </Row>
      </MiniBasketItem>
    );
  }
}

export default compose(
  withRouter,
  withTheme,
)(BasketItem);
