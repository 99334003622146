import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import { compose, graphql, withApollo } from 'react-apollo';
import {
  Button,
  Header,
  ImportantInformationTitle,
  ImportantInformationText,
  InformationInner,
  InformationOuter,
  Inner,
  Outer,
  ImportantInformationLink,
} from './BasketItemImportantInfo.style';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import { Icon, RichText } from '../ui';
import svgPlus from '../../static/images/icons/Plus.svg';
import svgMinus from '../../static/images/icons/Minus.svg';
import { getBasketItemImportantInfoMessage } from './helpers';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import { POST_TYPES, navigateTo } from '../../lib/helpers/navigation';

const propTypes = {
  isPastPenaltyDate: PropTypes.bool,
  penaltyDate: PropTypes.string,
  isOverseas: PropTypes.bool,
  client: PropTypes.shape({}).isRequired,
  termsAndConditionsLink: PropTypes.string.isRequired,
  isQuoteLevelImportantInfo: PropTypes.bool,
};

const defaultProps = {
  isPastPenaltyDate: false,
  penaltyDate: '',
  isOverseas: false,
  isQuoteLevelImportantInfo: false,
};

const BasketItemImportantInfo = ({
  isPastPenaltyDate,
  penaltyDate,
  isOverseas,
  client,
  termsAndConditionsLink,
  isQuoteLevelImportantInfo,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const informationOuterRef = useRef(null);

  const setImportantInformationHeight = useCallback(() => {
    if (informationOuterRef.current) {
      const currentHeight = informationOuterRef.current.style.maxHeight;
      informationOuterRef.current.style.maxHeight = 'none';
      // force reflow for measure height
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(informationOuterRef.current).height;
      const clientHeight = informationOuterRef.current?.clientHeight;
      informationOuterRef.current.style.maxHeight = currentHeight;
      // force reflow to reset
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(informationOuterRef.current).height;
      if (isOpen) {
        informationOuterRef.current.style.maxHeight = `${clientHeight}px`;
      } else {
        informationOuterRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  useLayoutEffect(
    setImportantInformationHeight,
    [setImportantInformationHeight],
  );

  // The height of the important information can changed depending on browser width, therefore
  // ensure we update this if the user resizes.
  const onResizeDebounced = useCallback(
    debounce(setImportantInformationHeight, 500),
    [debounce, setImportantInformationHeight],
  );

  useEffect(function initResize() {
    window.addEventListener('resize', onResizeDebounced, { passive: true });
    return () => window.removeEventListener('resize', onResizeDebounced);
  }, [onResizeDebounced]);

  const { message, linkTitle } = getBasketItemImportantInfoMessage(
    isOverseas, isPastPenaltyDate, penaltyDate, getDictionaryItem, client,
  );

  const handleLinkClick = (e) => {
    e.preventDefault();
    navigateTo(termsAndConditionsLink, { type: POST_TYPES.NEW_WINDOW });
  };

  // These are used to insert the terms link into the middle of the message.
  // Message before the link
  const messagePreLink = message.split('{{{')[0];
  // Message after the link
  const splitMessage = message.split('}}}');
  const messagePostLink = splitMessage?.[splitMessage.length - 1];

  return (
    <Outer isQuoteLevelImportantInfo={isQuoteLevelImportantInfo}>
      <Inner isOpen={isOpen}>
        <Header>
          <ImportantInformationTitle tag="div" size={3} dictionary={dictionaryItem('ManageMyBookings', 'BasketItemImportantInfo', 'Header')} />
          <Button onClick={() => setIsOpen(!isOpen)}>
            <Icon icon={isOpen ? svgMinus : svgPlus} />
          </Button>
        </Header>
        <InformationOuter ref={informationOuterRef} isOpen={isOpen}>
          <InformationInner>
            <ImportantInformationText tag="div" size={3}>
              <RichText inline>{messagePreLink}</RichText>
              {linkTitle && (
                <ImportantInformationLink href={termsAndConditionsLink} onClick={handleLinkClick}>
                  {linkTitle}
                </ImportantInformationLink>
              )}
              {messagePostLink && messagePreLink !== messagePostLink &&
                <RichText inline>{messagePostLink}</RichText>
              }
            </ImportantInformationText>
          </InformationInner>
        </InformationOuter>
      </Inner>
    </Outer>
  );
};

BasketItemImportantInfo.propTypes = propTypes;
BasketItemImportantInfo.defaultProps = defaultProps;

export default compose(
  withApollo,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      termsAndConditionsLink: data.configuration.termsAndConditionsLink,
    }),
  }),
)(BasketItemImportantInfo);
