import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { graphql, compose, withApollo } from 'react-apollo';

import isLoggedIn from '../../lib/isLoggedIn';
import scrollToTop from '../../lib/scrollToTop';
import routes from '../../constants/routes';

import { dictionaryItem } from '../../hocs/withDictionary';

import DialogBookingOverlap from '../Dialog/DialogBookingOverlap';
import DialogVouchers from '../Dialog/DialogVouchers';

import BasketCheckoutStyled from './BasketCheckout.style';

import GET_USER from '../../config/graphql/getUser';
import FetchPolicy from '../../constants/FetchPolicy';
import GET_POPUP from '../PopUp/graphql/getPopUp';

import QuoteAcceptComponent from '../Quote/QuoteAcceptComponent';
import testingAttr from '../../lib/testingAttr';
import { quoteHasNonMemberExclusiveProducts } from '../../lib/helpers/quote';
import IbePropTypes from '../../IbePropTypes';
import BasketScopedOverlappingSitesType from '../../config/basketScopedOverlappingSitesType';
import { BASKET_STATES } from '../../config/products';
import { RENEWAL_STATUSES } from '../../config/membershipStatus';
import { navigateTo } from '../../lib/helpers/navigation';
import { isMembershipInBasket } from '../../config/quoteMembershipTypes';

class BasketCheckout extends PureComponent {
  static propTypes = {
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.shape({}),
    extrasOnly: PropTypes.bool.isRequired,
    router: PropTypes.shape({
      query: PropTypes.shape({
        campaignCode: PropTypes.string,
      }),
    }).isRequired,
    toggleBasket: PropTypes.func.isRequired,
    basketScopedOverlappingBookings: PropTypes.bool.isRequired,
    handleUserAttemptedCheckout: PropTypes.func,
    handleUserMissingAddress: PropTypes.func,
    priceDecreaseItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    popups: PropTypes.shape(IbePropTypes.popups).isRequired,
    basketScopedOverlappingSitesType: PropTypes.string,
    quote: PropTypes.shape(IbePropTypes.quote),
    user: PropTypes.shape(IbePropTypes.user),
  }

  static defaultProps = {
    handleUserAttemptedCheckout: () => { },
    handleUserMissingAddress: () => { },
    disabled: false,
    error: null,
    basketScopedOverlappingSitesType: BasketScopedOverlappingSitesType.OFF,
    quote: {},
    user: undefined,
  }

  toggleDialog = (dialog, open) => {
    const { client } = this.props;

    if (open) {
      scrollToTop();
    }

    client.writeData({ data: { [dialog]: open } });
  }

  handleClick = () => {
    const {
      handleUserAttemptedCheckout,
      basketScopedOverlappingBookings,
      basketScopedOverlappingSitesType,
      toggleBasket,
      user: { canProceedToBooking, membershipStatus } = {},
    } = this.props;

    handleUserAttemptedCheckout();
    toggleBasket(true);

    // Show campsite overlap dialog prompt if there are overlapping dates and no bypass
    if (
      basketScopedOverlappingBookings
      && basketScopedOverlappingSitesType !== BasketScopedOverlappingSitesType.OFF
    ) {
      this.toggleDialog('bookingOverlapDialog', true);
      return;
    }

    this.proceedToCheckout(canProceedToBooking, membershipStatus);
  }

  proceedToCheckout = (canProceedToBooking, membershipStatus) => {
    const { quote } = this.props;
    const isOverseas = quote?.basketState === BASKET_STATES.OVERSEAS;
    const showProceed = quoteHasNonMemberExclusiveProducts(quote);
    if (isLoggedIn() && RENEWAL_STATUSES.includes(membershipStatus)) {
      this.toggleDialog('membershipExpiredPopUp', {
        __typename: 'MembershipExpiredPopUp',
        open: true,
        isOverseas,
        showProceed,
      });
      return;
    }
    if (!isLoggedIn() || (isLoggedIn() && !canProceedToBooking)) {
      localStorage.pendingLogin = true;
      this.toggleDialog('loginPopUp', {
        __typename: 'LoginPopUp',
        open: true,
        title: '',
        prompt: '',
        showMembership: !isMembershipInBasket(quote),
        redirectTo: `${routes.sites}?basketOpen=true`,
      });

      localStorage.pendingValidateQuoteRedirectToCheckout = true;
      return;
    }
    if (isLoggedIn()) {
      const { user: { hasAddress } = {}, handleUserMissingAddress } = this.props;

      if (!hasAddress) {
        handleUserMissingAddress();
        return;
      }
    }
    const { campaignCode } = this.props.router.query;

    let url = routes.checkout;
    if (campaignCode) {
      url += `?campaignCode=${campaignCode}`;
    }

    navigateTo(url);
  }

  handleCampsiteProceed = () => {
    const { user: { canProceedToBooking, membershipStatus } = {} } = this.props;

    this.toggleDialog('bookingOverlapDialog', false);
    this.proceedToCheckout(canProceedToBooking, membershipStatus);
  }

  render() {
    const { extrasOnly, popups } = this.props;

    const onContinue = this.props.basketScopedOverlappingSitesType
      === BasketScopedOverlappingSitesType.WARNING ?
      () => this.handleCampsiteProceed()
      : undefined;

    return (
      <>
        {popups &&
          <DialogBookingOverlap
            open={popups.bookingOverlapDialog}
            onCancel={() => this.toggleDialog('bookingOverlapDialog', false)}
            onContinue={onContinue}
          />
        }

        {extrasOnly ? (
          <>
            <DialogVouchers
              handleClose={() => this.toggleDialog('voucherDialog', false)}
              open={popups.voucherDialog}
            />
            <BasketCheckoutStyled
              dictionary={dictionaryItem('BasketCheckout')}
              onClick={() => this.toggleDialog('voucherDialog', true)}
              {...testingAttr('basket-checkout-button')}
            />
          </>
        ) : (
          <QuoteAcceptComponent
            onSuccess={() => this.handleClick()}
            componentIds={this.props.priceDecreaseItemIds}
            disabled={this.props.disabled}
          >
            <BasketCheckoutStyled
              dictionary={dictionaryItem('BasketCheckout')}
              {...testingAttr('basket-checkout-button')}
            />
          </QuoteAcceptComponent>
        )}
      </>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  graphql(GET_USER, {
    props: (props) => ({
      user: props.data.user,
    }),
    options: {
      fetchPolicy: FetchPolicy.CACHE_ONLY,
    },
    skip: props => !isLoggedIn(),
  }),
  graphql(GET_POPUP, {
    name: 'popups',
  }),
)(BasketCheckout);
