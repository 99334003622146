import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import Error from 'next/error';
import getConfig from 'next/config';

import { LoadingContainer } from '../components/Layouts/AppWrapper/index.style';
import LoadingSpinner from '../components/ui/Loading/LoadingSpinner';
import IbePropTypes from '../IbePropTypes';
import { fetchPolicyOptions, FETCH_POLICY_KEYS, setUpRefetchOnDateTime } from '../constants/FetchPolicy';
import getFetchPolicyWithExpiry from '../lib/getFetchPolicyWithExpiry';
import { setStorageItemList } from '../lib/storage';
import { CONFIG_LOAD_COMPLETE_TIME_IN_SESSION } from '../lib/constants';
import GET_CROSSINGS_CONFIG from '../config/graphql/getCrossingsConfig';

const withCrossingsConfig = (WrappedComponent) => {
  class WithCrossingsConfig extends React.PureComponent {
    static propTypes = {
      crossingsConfig: PropTypes.shape(IbePropTypes.crossingsConfig),
      crossingsConfigError: PropTypes.shape({}),
      crossingsConfigRefetch: PropTypes.func,
      isCrossingsConfigLoading: PropTypes.bool,
    }

    static defaultProps = {
      crossingsConfig: undefined,
      crossingsConfigError: undefined,
      crossingsConfigRefetch: () => { },
      isCrossingsConfigLoading: false,
    }

    timeoutId = undefined;

    componentDidMount() {
      const { publicRuntimeConfig } = getConfig();
      setUpRefetchOnDateTime(this.timeoutId, this.onRefetch, publicRuntimeConfig);
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutId);
    }

    onRefetch() {
      this.props.crossingsConfigRefetch();
      setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION.CROSSINGS_CONFIG, 'time', new Date().getTime());
    }

    render() {
      const {
        crossingsConfig,
        crossingsConfigError,
        isCrossingsConfigLoading,
        ...componentProps
      } = this.props;
      if (!crossingsConfig || isCrossingsConfigLoading) {
        return (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        );
      }

      if (crossingsConfigError) {
        console.error(
          'withCrossingsConfig.render() queryError:',
          crossingsConfigError,
        );
        return <Error />;
      }
      return (
        <WrappedComponent
          crossingsConfig={crossingsConfig}
          {...componentProps}
        />
      );
    }
  }

  return graphql(GET_CROSSINGS_CONFIG, {
    options: () => {
      const fetchPolicy = getFetchPolicyWithExpiry(
        FETCH_POLICY_KEYS.CROSSINGS_CONFIG, fetchPolicyOptions,
      );
      return {
        fetchPolicy,
        onCompleted: () => {
          setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION.CROSSINGS_CONFIG, 'time', new Date().getTime());
        },
      };
    },
    props: ({ data }) => ({
      crossingsConfig: data.configurationCrossings,
      crossingsConfigError: data.error,
      crossingsConfigRefetch: data.refetch,
      isCrossingsConfigLoading: data.isLoading,
    }),
  })(WithCrossingsConfig);
};

export default withCrossingsConfig;
