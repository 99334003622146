import gql from 'graphql-tag';

const GET_SITES_CONFIG = gql`
  query getSitesConfig {
    configurationSites @rest(type: "ConfigurationSites", path: "configuration/sites") {
      campaignCodes
      campingMiniImage
      campsiteTypes
      defaultCampsiteZoomLevel
      disabledFilterableFeatures
      escortedToursDurationTypes
      excludeGeoLocationTypes
      features
      featureTypes
      geoLocationPermissionsLink
      mapConfiguration
      nearbyFilterableFeatures
      onSiteFilterableFeatures
      overseasSitesTabDefaultSearchSortOrderTypes
      overseasSitesTabDefaultSearchPlaceTypes
      pitchTypesLink
      pointsOfInterestFilterableFeatures
      sortOrderTypes
      suggestedUkItineraryCategories
      ukSitesTabDefaultSearchSortOrderTypes
      ukSitesTabDefaultSearchPlaceTypes
    }
  }
`;

export default GET_SITES_CONFIG;
