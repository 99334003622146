import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import Error from 'next/error';
import getConfig from 'next/config';

import { LoadingContainer } from '../components/Layouts/AppWrapper/index.style';
import LoadingSpinner from '../components/ui/Loading/LoadingSpinner';
import IbePropTypes from '../IbePropTypes';
import { fetchPolicyOptions, FETCH_POLICY_KEYS, setUpRefetchOnDateTime } from '../constants/FetchPolicy';
import getFetchPolicyWithExpiry from '../lib/getFetchPolicyWithExpiry';
import { setStorageItemList } from '../lib/storage';
import { CONFIG_LOAD_COMPLETE_TIME_IN_SESSION } from '../lib/constants';
import GET_SITES_CONFIG from '../config/graphql/getSitesConfig';

const withSitesConfig = (WrappedComponent) => {
  class WithSitesConfig extends React.PureComponent {
    static propTypes = {
      sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig),
      sitesConfigError: PropTypes.shape({}),
      sitesConfigRefetch: PropTypes.func,
      isSitesConfigLoading: PropTypes.bool,
    }

    static defaultProps = {
      sitesConfig: undefined,
      sitesConfigError: undefined,
      sitesConfigRefetch: () => { },
      isSitesConfigLoading: false,
    }

    timeoutId = undefined;

    componentDidMount() {
      const { publicRuntimeConfig } = getConfig();
      setUpRefetchOnDateTime(this.timeoutId, this.onRefetch, publicRuntimeConfig);
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutId);
    }

    onRefetch() {
      this.props.sitesConfigRefetch();
      setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION.SITES_CONFIG, 'time', new Date().getTime());
    }

    render() {
      const {
        sitesConfig,
        sitesConfigError,
        isSitesConfigLoading,
        ...componentProps
      } = this.props;

      if (!sitesConfig || isSitesConfigLoading) {
        return (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        );
      }

      if (sitesConfigError) {
        console.error(
          'withSitesConfig.render() queryError:',
          sitesConfigError,
        );
        return <Error />;
      }

      return (
        <WrappedComponent
          sitesConfig={sitesConfig}
          {...componentProps}
        />
      );
    }
  }

  return graphql(GET_SITES_CONFIG, {
    options: () => {
      const fetchPolicy = getFetchPolicyWithExpiry(
        FETCH_POLICY_KEYS.SITES_CONFIG, fetchPolicyOptions,
      );
      return {
        fetchPolicy,
        onCompleted: () => {
          setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION.SITES_CONFIG, 'time', new Date().getTime());
        },
        onError: (error) => {
          console.error('Query error:', error);
        },
      };
    },
    props: ({ data }) => ({
      sitesConfig: data.configurationSites,
      sitesConfigError: data.error,
      sitesConfigRefetch: data.refetch,
      isSitesConfigLoading: data.isLoading,
    }),
  })(WithSitesConfig);
};

export default withSitesConfig;
