import styled, { css } from 'styled-components';

export const ScrollableContent = styled.div`
  overflow-y: auto;
  height: 100%;
  position: relative;

  ${({ showShadow, theme }) => css`
    background-color: ${theme.COLOR_WHITE};

    &:after {
      content: " ";
      display: block;
      background: linear-gradient(0deg, ${theme.COLOR_GRAY} 0%, transparent 100%);
      height: 20px;
      width: 100%;
      position: sticky;
      bottom: 0;
      pointer-events: none;
      opacity: ${showShadow ? '1' : '0'};
    }
  `}
`;
export default {};
