import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { ScrollableContent } from './GenericScrollContainer.style';

// Component used to wrap any scrollable content and add the
// shadow at the bottom which CAMC use in their designs
const GenericScrollContainer = ({ children }) => {
  const [hasReachedBottom, setHasReachedBottom] = useState(true);
  const scrollContainerRef = useRef();

  const updateHasReachedBottom = (node) => {
    const { clientHeight, scrollHeight, scrollTop } = node;
    const hasReachedEnd =
      Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
    setHasReachedBottom(hasReachedEnd);
  };

  const handleScroll = useCallback((e) => {
    updateHasReachedBottom(e.target);
  }, []);

  useEffect(() => {
    // Run once on mount to set the default state value properly
    updateHasReachedBottom(scrollContainerRef.current);

    // Check if we've scrolled to the bottom of modal,
    // if so, remove shadow (set hasReachedBottom to true)
    // and vice versa
    if (scrollContainerRef.current !== null) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll, {
        passive: true,
      });
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll, {
          passive: true,
        });
      }
    };
  }, [scrollContainerRef, children]);

  return (
    <ScrollableContent
      showShadow={!hasReachedBottom}
      ref={scrollContainerRef}
    >
      {children}
    </ScrollableContent>
  );
};

GenericScrollContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

GenericScrollContainer.defaultProps = {
  children: <></>,
};

export default GenericScrollContainer;
